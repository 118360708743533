import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Task } from "./Task";
import { TaskList } from "./TaskList";

import { Menu } from "./Menu";
import { AdminTaskList } from "./AdminTaskList";
import { AdminTask } from "./AdminTask";
import { TaskMobile } from "./TaskMobile";
import { AdminTaskMobile } from "./AdminTaskMobile";

export const StoreComplianceToolBody = () => {
  const [page, setPage] = useState("menu");
  const [StoreComplianceToolResponse, setStoreComplianceToolResponse] = useState("");

  const [SelectedTaskID, setSelectedTaskID] = useState("");
  const [SelectedSubmissionID, setSelectedSubmissionID] = useState("");

  const [From, setFrom] = useState("");

  const handleClick_Home = () => {
    if (process.env.NODE_ENV === "production") {
      let uri = "";
      switch (From.Page) {
        case "STORE":
          uri = "http://192.168.15.201/TM";
          break;
        default:
        case "HO":
          uri = "/#/";
          break;
      }
      window.location.href = uri;
    } else {
      window.location.href = "/#/";
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (searchParams !== null) {
      const params = new URLSearchParams(searchParams);

      const _from = params.get("f") || "";
      const _fromStore = params.get("s") || "";
      const _fromStoreName = params.get("n") || "";
      const _fromUserID = params.get("u") || "";
      setFrom({
        Page: _from,
        StoreNo: _fromStore,
        StoreName: _fromStoreName,
        UserID: _fromUserID,
      });
    }
  }, []);

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      handleClick_Home={handleClick_Home}
    />
  );

  const taskListPage = (
    <TaskList
      page={page}
      setPage={setPage}
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      setSelectedSubmissionID={setSelectedSubmissionID}
      From={From}
      handleClick_Home={handleClick_Home}
    />
  );

  const taskPage = (
    <Task
      page={page}
      setPage={setPage}
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      SelectedSubmissionID={SelectedSubmissionID}
      setSelectedSubmissionID={setSelectedSubmissionID}
      handleClick_Home={handleClick_Home}
    />
  );

  const taskMobilePage = (
    <TaskMobile
      page={page}
      setPage={setPage}
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      SelectedSubmissionID={SelectedSubmissionID}
      setSelectedSubmissionID={setSelectedSubmissionID}
      handleClick_Home={handleClick_Home}
    />
  );

  const adminTaskListPage = (
    <AdminTaskList
      page={page}
      setPage={setPage}
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      handleClick_Home={handleClick_Home}
      SelectedTaskID={SelectedTaskID}
      setSelectedTaskID={setSelectedTaskID}
    />
  );

  const adminTaskCreatePage = (
    <AdminTask
      page={page}
      setPage={setPage}
      PageType="CREATE"
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      SelectedTaskID={SelectedTaskID}
      setSelectedTaskID={setSelectedTaskID}
    />
  );

  const adminTaskEditPage = (
    <AdminTask
      page={page}
      setPage={setPage}
      PageType="EDIT"
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      SelectedTaskID={SelectedTaskID}
      setSelectedTaskID={setSelectedTaskID}
    />
  );

  const adminTaskViewPage = (
    <AdminTask
      page={page}
      setPage={setPage}
      PageType="VIEW"
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      SelectedTaskID={SelectedTaskID}
      setSelectedTaskID={setSelectedTaskID}
    />
  );

  const adminTaskMobileCreatePage = (
    <AdminTaskMobile
      page={page}
      setPage={setPage}
      PageType="CREATE"
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      SelectedTaskID={SelectedTaskID}
      setSelectedTaskID={setSelectedTaskID}
    />
  );

  const adminTaskMobileEditPage = (
    <AdminTaskMobile
      page={page}
      setPage={setPage}
      PageType="EDIT"
      StoreComplianceToolResponse={StoreComplianceToolResponse}
      setStoreComplianceToolResponse={setStoreComplianceToolResponse}
      From={From}
      SelectedTaskID={SelectedTaskID}
      setSelectedTaskID={setSelectedTaskID}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "tasklist":
        return taskListPage;
      case "task":
        return taskPage;
      case "task-mobile":
        return taskMobilePage;
      case "admin-tasklist":
        return adminTaskListPage;
      case "admin-task-create":
        return adminTaskCreatePage;
      case "admin-task-mobile-create":
        return adminTaskMobileCreatePage;
      case "admin-task-edit":
        return adminTaskEditPage;
      case "admin-task-mobile-edit":
        return adminTaskMobileEditPage;
      case "admin-task-view":
        return adminTaskViewPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
