import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import {
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
  yellow,
} from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { useRef } from "react";
import { PopupPrint } from "./PopupPrint";
import store from "../../store";

export const PriceChangesCategories = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [LabelResponse, setLabelResponse] = useState("");

  const [CategoryList, setCategoryList] = useState([]);
  const [IsSOHOnlyChecked, setIsSOHOnlyChecked] = useState(true);

  const [showPrint, setShowPrint] = useState(false);

  const refSOHOnly = useRef();

  const handleClick_Back = () => {
    props.resetPriceChangesBatchDetails();
    props.setPage("pricechanges-settings");
  };

  const handleClick_SOHOnly = () => {
    setIsSOHOnlyChecked(refSOHOnly.current.checked);
  };

  const getCategoryList = async () => {
    const resObj = await runFetch(spURL + "Handheld_Label_PriceChange_CategoryList_Get", {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          PriceChangeMode: props.PriceChangesBatchDetails.Mode,
          EDM: props.PriceChangesBatchDetails.EDM,
        }),
      }),
    });
    if (resObj.exception !== "") {
      setLabelResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCategoryList(
          spResponse.Table.map((item) => ({
            Category: item.Category,
            TotalToPrint: item.TotalToPrint,
            RemainingToPrint: item.RemainingToPrint,
            SOH: item.SOH,
          }))
        );
      } else {
        setCategoryList([]);
      }
    }
  };

  const handleClick_Print = () => {
    setShowPrint(true);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Batch:</strong>
          </p>
          <p>{props.PriceChangesBatchDetails.Batch}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Printer:</strong>
          </p>
          <p>{props.PriceChangesBatchDetails.Printer}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Quick Shelf:</strong>
          </p>
          <p>{props.PriceChangesBatchDetails.IsPrintQuickShelfChecked ? "Y" : "N"}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Mode:</strong>
          </p>
          <p>{props.PriceChangesBatchDetails.Mode}</p>
        </div>
        {props.PriceChangesBatchDetails.Mode === "EDM" && (
          <div className="stocktake-create-input-row">
            <p>
              <strong>EDM:</strong>
            </p>
            <p>{props.PriceChangesBatchDetails.EDM}</p>
          </div>
        )}
        <p className="error-message">{LabelResponse}</p>
        {CategoryList.length !== 0 && (
          <>
            <div className="weborders-sohonly">
              <div className="weborders-printweblabels-container">
                <input
                  type="checkbox"
                  id="weborders-printweblabels"
                  ref={refSOHOnly}
                  name="weborders-printweblabels"
                  defaultChecked
                  onClick={handleClick_SOHOnly}
                />
                <label htmlFor="weborders-printweblabels">In stock items only</label>
              </div>
            </div>
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Category</th>
                  <th colSpan={2}>To Print</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Remaining</th>
                </tr>
              </thead>
              <tbody>
                {CategoryList.filter((item) => {
                  if (IsSOHOnlyChecked) {
                    return parseInt(item.SOH) > 0;
                  } else {
                    return item;
                  }
                }).map((item, index) => (
                  <tr
                    key={index}
                    className="btnHover"
                    onClick={() => {
                      props.setPriceChangesCategory(item.Category);
                      props.setPage("pricechanges-items");
                    }}
                  >
                    <td>{item.Category}</td>
                    <td>{item.TotalToPrint}</td>
                    <td>{item.RemainingToPrint}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        <PopupPrint
          show={showPrint}
          setShow={setShowPrint}
          From="PRICECHANGE"
          BatchID={props.PriceChangesBatchDetails.Batch}
          ShelfPrinter={props.PriceChangesBatchDetails.Printer}
          setMenuResponse={setLabelResponse}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        <FooterButton
          button="Print"
          colour={yellow}
          onClick={handleClick_Print}
        />
      </div>
    </div>
  );
};
