import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const PopupConfirmBack = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleClick_Yes = () => {
    props.setShow(false);
    props.setPage("admin-tasklist");
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>Your unsaved edits will be discarded. Proceed?</strong>
            </p>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              No
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
