import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const PopupConfirmDelete = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleClick_Yes = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreComplianceToolAdmin_Task_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TaskID: props.SelectedTask.TaskID,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        props.setStoreComplianceToolResponse(spResponse.Table[0].OutputString);
        props.getTaskList();
        handleClose();
      } else {
        setPopupResponse("Server error. Please try again later.");
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>Confirm delete task {props.SelectedTask.TaskTitle}?</strong>
            </p>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              No
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
