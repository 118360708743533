import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { QuickLabel } from "./QuickLabel";
import { Update } from "./Update";
import { MerchLabelMenu } from "./MerchLabelMenu";
import { MerchLabelCreate } from "./MerchLabelCreate";
import { MerchLabelAdd } from "./MerchLabelAdd";
import { MerchLabelPrint } from "./MerchLabelPrint";
import { BayLabel } from "./BayLabel";
import { MerchandisingCategories, PriceChangesCategories } from "./PriceChangesCategories";
import { MerchandisingItems, PriceChangesItems } from "./PriceChangesItems";
import { PriceChangesSettings } from "./PriceChangesSettings";

export const LabelsBody = () => {
  const [page, setPage] = useState("menu");
  const [barcode, setBarcode] = useState("");
  const [currentShelfPrinter, setCurrentShelfPrinter] = useState("");
  const [currentMerchBatchID, setCurrentMerchBatchID] = useState("");
  const [currentMerchBatchPrinter, setCurrentMerchBatchPrinter] = useState("");
  const [PriceChangesCategory, setPriceChangesCategory] = useState("");
  const [PriceChangesBatchDetails, setPriceChangesBatchDetails] = useState({
    Batch: "",
    Printer: "",
    IsPrintQuickShelfChecked: true,
    Mode: "",
    EDM: "",
  });
  const [response, setResponse] = useState("");

  const resetPriceChangesBatchDetails = () => {
    setPriceChangesBatchDetails({
      Batch: "",
      Printer: "",
      IsPrintQuickShelfChecked: true,
      Mode: "",
      EDM: "",
    });
  };

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
    />
  );

  const quickLabelPage = (
    <QuickLabel
      page={page}
      setPage={setPage}
      setBarcode={setBarcode}
      Response={response}
      setResponse={setResponse}
      currentMerchBatchID={currentMerchBatchID}
      setCurrentMerchBatchID={setCurrentMerchBatchID}
      currentShelfPrinter={currentShelfPrinter}
      setCurrentShelfPrinter={setCurrentShelfPrinter}
    />
  );

  const updateQuickFeaturePage = (
    <Update
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
      Origin="QUICK"
      Barcode={barcode}
      setBarcode={setBarcode}
      currentMerchBatchID={currentMerchBatchID}
      setCurrentMerchBatchID={setCurrentMerchBatchID}
      currentShelfPrinter={currentShelfPrinter}
      setCurrentShelfPrinter={setCurrentShelfPrinter}
    />
  );

  const updateQuitFeaturePage = (
    <Update
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
      Origin="QUIT"
      Barcode={barcode}
      setBarcode={setBarcode}
    />
  );

  const merchLabelMenuPage = (
    <MerchLabelMenu
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
    />
  );

  const merchLabelCreatePage = (
    <MerchLabelCreate
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
      setBarcode={setBarcode}
      setCurrentMerchBatchID={setCurrentMerchBatchID}
      setCurrentMerchBatchPrinter={setCurrentMerchBatchPrinter}
    />
  );

  const merchLabelAddPage = (
    <MerchLabelAdd
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
      setBarcode={setBarcode}
      currentMerchBatchID={currentMerchBatchID}
      setCurrentMerchBatchID={setCurrentMerchBatchID}
      currentMerchBatchPrinter={currentMerchBatchPrinter}
      setCurrentMerchBatchPrinter={setCurrentMerchBatchPrinter}
    />
  );

  const updateMerchPage = (
    <Update
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
      Origin="MERCH"
      Barcode={barcode}
      setBarcode={setBarcode}
      currentMerchBatchID={currentMerchBatchID}
      setCurrentMerchBatchID={setCurrentMerchBatchID}
      currentMerchBatchPrinter={currentMerchBatchPrinter}
      setCurrentMerchBatchPrinter={setCurrentMerchBatchPrinter}
    />
  );

  const merchLabelPrintPage = (
    <MerchLabelPrint
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
      Barcode={barcode}
      setBarcode={setBarcode}
      currentMerchBatchID={currentMerchBatchID}
      setCurrentMerchBatchID={setCurrentMerchBatchID}
      currentMerchBatchPrinter={currentMerchBatchPrinter}
      setCurrentMerchBatchPrinter={setCurrentMerchBatchPrinter}
    />
  );

  const bayLabelPage = (
    <BayLabel
      page={page}
      setPage={setPage}
      Response={response}
      setResponse={setResponse}
    />
  );

  const priceChangesCategoriesPage = (
    <PriceChangesCategories
      page={page}
      setPage={setPage}
      setPriceChangesCategory={setPriceChangesCategory}
      PriceChangesBatchDetails={PriceChangesBatchDetails}
      setPriceChangesBatchDetails={setPriceChangesBatchDetails}
      resetPriceChangesBatchDetails={resetPriceChangesBatchDetails}
    />
  );

  const priceChangesItemsPage = (
    <PriceChangesItems
      page={page}
      setPage={setPage}
      PriceChangesCategory={PriceChangesCategory}
      setPriceChangesCategory={setPriceChangesCategory}
      PriceChangesBatchDetails={PriceChangesBatchDetails}
    />
  );

  const priceChangesSettingsPage = (
    <PriceChangesSettings
      page={page}
      setPage={setPage}
      PriceChangesBatchDetails={PriceChangesBatchDetails}
      setPriceChangesBatchDetails={setPriceChangesBatchDetails}
      resetPriceChangesBatchDetails={resetPriceChangesBatchDetails}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "quicklabel":
        return quickLabelPage;
      case "update-quick-feature":
        return updateQuickFeaturePage;
      case "merchlabel-print":
        return merchLabelPrintPage;
      case "update-merch":
        return updateMerchPage;
      case "merchlabel-menu":
        return merchLabelMenuPage;
      case "merchlabel-create":
        return merchLabelCreatePage;
      case "merchlabel-add":
        return merchLabelAddPage;
      case "update-quit-feature":
        return updateQuitFeaturePage;
      case "baylabel":
        return bayLabelPage;
      case "pricechanges-categories":
        return priceChangesCategoriesPage;
      case "pricechanges-items":
        return priceChangesItemsPage;
      case "pricechanges-settings":
        return priceChangesSettingsPage;
      default:
        break;
    }
  };

  useEffect(() => {}, []);

  return getCurrentPage(page);
};
