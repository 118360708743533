import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { blue, spURL, spURL_NoInputJSON } from "../../App";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { PopupCreateTemplate } from "./PopupCreateTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useId } from "react";
import { PopupCopyTemplate } from "./PopupCopyTemplate";

export const Template = (props) => {
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [showCopyTemplate, setShowCopyTemplate] = useState(false);
  const [showAssignedStore, setShowAssignedStore] = useState(false);

  const [CheckListResponse, setCheckListResponse] = useState("");
  const [TemplateList, setTemplateList] = useState([]);
  const [TemplateItems, setTemplateItems] = useState([]);

  const emptyTemplate = {
    TemplateID: "0",
    TemplateName: "",
    Note: "",
    IsActive: "0",
  };
  const [SelectedTemplate, setSelectedTemplate] = useState(emptyTemplate);

  const emptyTemplateItem = {
    TemplateItemID: "0",
    CheckListDescription: "",
    MON: "0",
    TUE: "0",
    WED: "0",
    THU: "0",
    FRI: "0",
    SAT: "0",
    SUN: "0",
  };

  const [CurrentTemplate, setCurrentTemplate] = useState(emptyTemplate);
  const [CurrentTemplateItem, setCurrentTemplateItem] = useState(emptyTemplateItem);

  const refNote = useRef();
  const refTemplateName = useRef();
  const refIsActiveYes = useRef();
  const refIsActiveNo = useRef();

  const IsActiveYesID = useId();
  const IsActiveNoID = useId();

  const refCheckListDescription = useRef();

  const getTemplateList = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_CheckList_DDLTemplate_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setCheckListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setTemplateList(spResponse.Table);
      }
    }
  };

  const getTemplateItems = async (_selectedTemplateID) => {
    const resObj = await runFetch(`${spURL}Handheld_CheckList_TemplateItems_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TemplateID: _selectedTemplateID,
        }),
      }),
    });

    if (resObj.response === null) {
      setCheckListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setTemplateItems(spResponse.Table);
      }
    }
  };

  const handleClick_New = () => setShowCreateTemplate(true);

  const handleClick_Copy = () => setShowCopyTemplate(true);

  const handleClick_Store = () => setShowAssignedStore(false);

  const handleClick_Cancel = () => {
    setCheckListResponse("");
    setSelectedTemplate(emptyTemplate);
    setCurrentTemplate(emptyTemplate);
    resetCurrentTemplateItem();
  };

  const handleClick_SaveHeader = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CheckList_Template_Edit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TemplateID: SelectedTemplate.TemplateID,
          TemplateName: refTemplateName.current.value,
          Note: refNote.current.value,
          IsActive: refIsActiveYes.current.checked ? "1" : "0",
        }),
      }),
    });

    if (resObj.response === null) {
      setCheckListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      await getTemplateList();
      await getTemplateItems(SelectedTemplate.TemplateID);

      setCurrentTemplate(emptyTemplate);
      setSelectedTemplate((prev) => ({
        ...prev,
        TemplateName: spResponse.Table[0].TemplateName,
        Note: spResponse.Table[0].Note,
        IsActive: spResponse.Table[0].IsActive.toString(),
      }));

      setCheckListResponse(spResponse.Table[0].OutputString);
    }
  };

  const handleClick_SaveItem = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CheckList_TemplateItems_Edit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(CurrentTemplateItem),
      }),
    });

    if (resObj.response === null) {
      setCheckListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      await getTemplateItems(SelectedTemplate.TemplateID);

      resetCurrentTemplateItem();
      setCheckListResponse(spResponse.Table[0].OutputString);
    }
  };

  const handleChange_CheckListDescription = (templateItemID, e) => {
    if (CurrentTemplateItem.TemplateItemID === templateItemID) {
      setCurrentTemplateItem((item) => ({
        ...item,
        CheckListDescription: e.target.value,
      }));
    }
  };

  const handleClick_Days = (templateItemID, day) => {
    if (CurrentTemplateItem.TemplateItemID === templateItemID) {
      setCurrentTemplateItem((prev) => ({
        ...prev,
        [day]: prev[day] === "1" ? "0" : "1",
      }));
    }
  };

  const handleClick_AddItem = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CheckList_TemplateItems_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ...CurrentTemplateItem,
          TemplateID: SelectedTemplate.TemplateID,
        }),
      }),
    });

    if (resObj.response === null) {
      setCheckListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      await getTemplateItems(SelectedTemplate.TemplateID);
      resetCurrentTemplateItem();
      setCheckListResponse(spResponse.Table[0].OutputString);
    }
  };

  const resetCurrentTemplateItem = () => {
    setCurrentTemplateItem(emptyTemplateItem);
  };

  useEffect(() => {
    getTemplateList();
  }, []);

  useEffect(() => {
    if (SelectedTemplate.TemplateID !== "0") {
      getTemplateItems(SelectedTemplate.TemplateID);
    }
  }, [SelectedTemplate]);

  useEffect(() => {
    if (SelectedTemplate.TemplateID !== "0" && CurrentTemplate.TemplateID !== "0") {
      refTemplateName.current.value = SelectedTemplate.TemplateName;
      refNote.current.value = SelectedTemplate.Note;

      SelectedTemplate.IsActive.toString() === "1"
        ? (refIsActiveYes.current.checked = true)
        : (refIsActiveNo.current.checked = true);

      refTemplateName.current.focus();
    }
  }, [SelectedTemplate, CurrentTemplate]);

  return (
    <div className="unallocatedorders-page-container">
      <div className="webbuffer-top-row">
        <h4>
          <strong>Check List Template</strong>
          {SelectedTemplate.TemplateID !== "0" &&
            (CurrentTemplate.TemplateID !== "0" ? (
              <>
                <span className="padding-5">
                  <FontAwesomeIcon
                    className="btnHover color-red"
                    icon={solid("xmark")}
                    onClick={() => {
                      setCheckListResponse("");
                      setCurrentTemplate(emptyTemplate);
                    }}
                  />
                </span>
                <span className="padding-5">
                  <FontAwesomeIcon
                    className="btnHover color-green"
                    icon={solid("check")}
                    onClick={() => {
                      setCheckListResponse("");
                      handleClick_SaveHeader();
                    }}
                  />
                </span>
              </>
            ) : (
              <span className="padding-5">
                <FontAwesomeIcon
                  className="btnHover"
                  icon={regular("pen-to-square")}
                  onClick={() => {
                    setCheckListResponse("");
                    setCurrentTemplate(SelectedTemplate);
                  }}
                />
              </span>
            ))}
        </h4>
      </div>
      <div>
        <div className="blockedorders-content-container">
          <p className="error-message">{CheckListResponse}</p>
          {SelectedTemplate.TemplateID === "0" ? (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Template</th>
                </tr>
              </thead>
              <tbody>
                {TemplateList.sort(
                  (a, b) => parseInt(b.IsActive, 10) - parseInt(a.IsActive, 10)
                ).map((template, index) => (
                  <tr
                    key={index}
                    className={`btnHover ${
                      template.IsActive.toString() === "1" && "background-lightgreen"
                    }`}
                    onClick={() => {
                      setCheckListResponse("");
                      setSelectedTemplate(template);
                    }}
                  >
                    <td>{template.TemplateName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Name</strong>
                </p>
                {CurrentTemplate.TemplateID === "0" ? (
                  <p>{SelectedTemplate.TemplateName}</p>
                ) : (
                  <input
                    type="text"
                    ref={refTemplateName}
                  />
                )}
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Note</strong>
                </p>
                {CurrentTemplate.TemplateID === "0" ? (
                  <p>{SelectedTemplate.Note}</p>
                ) : (
                  <textarea
                    rows={5}
                    ref={refNote}
                  ></textarea>
                )}
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Active?</strong>
                </p>
                {CurrentTemplate.TemplateID === "0" ? (
                  <p>{SelectedTemplate.IsActive.toString() === "1" ? "Yes" : "No"}</p>
                ) : (
                  <div className="popup-radio-container">
                    <div className="printstoreaddress-settings-radio-container">
                      <input
                        type="radio"
                        id={IsActiveYesID}
                        ref={refIsActiveYes}
                        name="from-address"
                        value="1"
                      />
                      <label htmlFor={IsActiveYesID}>Yes</label>
                    </div>
                    <div className="printstoreaddress-settings-radio-container">
                      <input
                        type="radio"
                        id={IsActiveNoID}
                        ref={refIsActiveNo}
                        name="from-address"
                        value="0"
                        defaultChecked
                      />
                      <label htmlFor={IsActiveNoID}>No</label>
                    </div>
                  </div>
                )}
              </div>
              <h4>
                <strong>Check List Items</strong>
              </h4>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th rowSpan={2}></th>
                    <th rowSpan={2}>Description</th>
                    <th colSpan={7}>Days</th>
                    <th rowSpan={2}></th>
                  </tr>
                  <tr>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                    <th>Sun</th>
                  </tr>
                </thead>
                <tbody>
                  {TemplateItems.map((item, index) => {
                    if (item.TemplateItemID === CurrentTemplateItem.TemplateItemID) {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <input
                              type="text"
                              className="full-width"
                              onChange={(e) =>
                                handleChange_CheckListDescription(item.TemplateItemID, e)
                              }
                              value={CurrentTemplateItem.CheckListDescription}
                              ref={refCheckListDescription}
                            />
                          </td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.MON === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "MON")}
                          ></td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.TUE === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "TUE")}
                          ></td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.WED === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "WED")}
                          ></td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.THU === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "THU")}
                          ></td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.FRI === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "FRI")}
                          ></td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.SAT === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "SAT")}
                          ></td>
                          <td
                            className={`btnHover ${
                              CurrentTemplateItem.SUN === "1" ? "background-lightgreen" : ""
                            }`}
                            onClick={() => handleClick_Days(item.TemplateItemID, "SUN")}
                          ></td>
                          <td>
                            <FontAwesomeIcon
                              className="btnHover color-red"
                              icon={solid("xmark")}
                              onClick={resetCurrentTemplateItem}
                            />
                            <FontAwesomeIcon
                              className="btnHover color-green"
                              icon={solid("check")}
                              onClick={handleClick_SaveItem}
                            />
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-align-left">{item.CheckListDescription}</td>
                          <td className={item.MON === "1" ? "background-lightgreen" : ""}></td>
                          <td className={item.TUE === "1" ? "background-lightgreen" : ""}></td>
                          <td className={item.WED === "1" ? "background-lightgreen" : ""}></td>
                          <td className={item.THU === "1" ? "background-lightgreen" : ""}></td>
                          <td className={item.FRI === "1" ? "background-lightgreen" : ""}></td>
                          <td className={item.SAT === "1" ? "background-lightgreen" : ""}></td>
                          <td className={item.SUN === "1" ? "background-lightgreen" : ""}></td>
                          <td>
                            <FontAwesomeIcon
                              className="btnHover"
                              icon={regular("pen-to-square")}
                              onClick={() => setCurrentTemplateItem(item)}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                  <tr>
                    <td></td>
                    <td>
                      <input
                        type="text"
                        className="full-width"
                        onChange={(e) => handleChange_CheckListDescription("0", e)}
                        value={
                          CurrentTemplateItem.TemplateItemID === "0"
                            ? CurrentTemplateItem.CheckListDescription
                            : ""
                        }
                      />
                    </td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.MON === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "MON")}
                    ></td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.TUE === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "TUE")}
                    ></td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.WED === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "WED")}
                    ></td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.THU === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "THU")}
                    ></td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.FRI === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "FRI")}
                    ></td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.SAT === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "SAT")}
                    ></td>
                    <td
                      className={
                        CurrentTemplateItem.TemplateItemID === "0" &&
                        CurrentTemplateItem.SUN === "1"
                          ? "btnHover background-lightgreen"
                          : ""
                      }
                      onClick={() => handleClick_Days("0", "SUN")}
                    ></td>
                    <td>
                      <FontAwesomeIcon
                        className="btnHover"
                        onClick={handleClick_AddItem}
                        icon={solid("plus")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          <PopupCreateTemplate
            show={showCreateTemplate}
            setShow={setShowCreateTemplate}
            setCheckListResponse={setCheckListResponse}
            setSelectedTemplate={setSelectedTemplate}
            getTemplateList={getTemplateList}
          />

          <PopupCopyTemplate
            show={showCopyTemplate}
            setShow={setShowCopyTemplate}
            setCheckListResponse={setCheckListResponse}
            SelectedTemplate={SelectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            getTemplateList={getTemplateList}
          />
        </div>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={() => {
            if (SelectedTemplate.TemplateID === "0") {
              props.setPage("menu");
            } else {
              handleClick_Cancel();
            }
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
        {SelectedTemplate.TemplateID === "0" && (
          <FooterButton
            button="New"
            colour={blue}
            onClick={handleClick_New}
          />
        )}
        {SelectedTemplate.TemplateID !== "0" && TemplateItems.length > 0 && (
          <>
            <FooterButton
              button="Copy"
              colour={blue}
              onClick={handleClick_Copy}
            />
            <FooterButton
              button="Store"
              colour={blue}
              onClick={handleClick_Store}
            />
          </>
        )}
      </div>
    </div>
  );
};
