import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, blue } from "../../App";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PopupConfirmDelete } from "./PopupConfirmDelete";
import is from "is_js";

export const AdminTaskList = (props) => {
  const [StoreComplianceToolResponse, setStoreComplianceToolResponse] = useState(
    props.StoreComplianceToolResponse
  );

  const [TaskList, setTaskList] = useState([]);
  const [StatusFilter, setStatusFilter] = useState("DUE");
  const [SelectedTask, setSelectedTask] = useState({
    TaskID: 0,
    TaskTitle: "",
  });

  const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);

  const handleClick_Create = () => {
    setStoreComplianceToolResponse("");
    props.setPage(`admin-task${is.mobile() ? "-mobile" : ""}-create`);
  };

  const getTaskList = async () => {
    const resObj = await runFetch(
      `${spURL_NoInputJSON}Handheld_StoreComplianceToolAdmin_TaskList_Get`,
      {
        method: "POST",
      }
    );

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setTaskList(spResponse.Table);
      } else {
        setTaskList([]);
      }
    }
  };

  useEffect(() => {
    getTaskList();
  }, [props.From]);

  return (
    <div className="weborders-page-container">
      <div className="weborders-top-row">
        <div className="weborders-manager-option-row">
          <ContentButton
            button=""
            colour="white"
            onClick={() => {
              setStoreComplianceToolResponse("");
              setStatusFilter("DUE");
            }}
          />
          <ContentButton
            button=""
            colour="lightgreen"
            onClick={() => {
              setStoreComplianceToolResponse("");
              setStatusFilter("COMPLETED");
            }}
          />
        </div>
      </div>
      <div className="desktop-body-container">
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Task</th>
              <th>
                <FontAwesomeIcon
                  className="no-margin"
                  icon={solid("calendar-days")}
                />
              </th>
              <th>
                <FontAwesomeIcon
                  className="no-margin"
                  icon={solid("house")}
                />
              </th>
              <th>
                <FontAwesomeIcon
                  className="no-margin"
                  icon={solid("house-circle-check")}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {TaskList.filter((task) => {
              switch (StatusFilter) {
                case "DUE":
                  return ["NEW", "PROGRESS"].includes(task.Status);
                case "COMPLETED":
                  return ["COMPLETED"].includes(task.Status);
              }
            }).map((task, index) => (
              <tr
                key={index}
                className={`btnHover background-${
                  task.Status === "COMPLETED"
                    ? "lightgreen"
                    : task.Status === "PROGRESS"
                    ? "yellow"
                    : "white"
                }`}
              >
                <td>{task.TaskTitle}</td>
                <td>{ConvertDateFormat(task.TaskDueDate, "en-CA")}</td>
                <td>{task.Assigned}</td>
                <td>{task.Completed}</td>
                <td>
                  <FontAwesomeIcon
                    icon={solid("binoculars")}
                    onClick={() => {
                      props.setStoreComplianceToolResponse("");
                      setStoreComplianceToolResponse("");
                      props.setSelectedTaskID(task.TaskID);
                      props.setPage("admin-task-view");
                    }}
                  />
                  {task.Status !== "COMPLETED" && (
                    <>
                      <FontAwesomeIcon
                        icon={solid("pen-to-square")}
                        onClick={() => {
                          props.setStoreComplianceToolResponse("");
                          setStoreComplianceToolResponse("");
                          props.setSelectedTaskID(task.TaskID);
                          props.setPage(`admin-task${is.mobile() ? "-mobile" : ""}-edit`);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={solid("xmark")}
                        className="color-red"
                        onClick={() => {
                          props.setStoreComplianceToolResponse("");
                          setStoreComplianceToolResponse("");
                          setSelectedTask({ TaskID: task.TaskID, TaskTitle: task.TaskTitle });
                          setShowPopupConfirmDelete(true);
                        }}
                      />
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="error-message">{StoreComplianceToolResponse}</p>

        <PopupConfirmDelete
          show={showPopupConfirmDelete}
          setShow={setShowPopupConfirmDelete}
          SelectedTask={SelectedTask}
          setStoreComplianceToolResponse={setStoreComplianceToolResponse}
          getTaskList={getTaskList}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={() => {
            props.setStoreComplianceToolResponse("");
            props.setPage("menu");
          }}
        />
        <FooterButton
          button="Home"
          colour={blue}
          onClick={props.handleClick_Home}
        />
        <FooterButton
          button="Create"
          colour={blue}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
