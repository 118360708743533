import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, blue } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import is from "is_js";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const TaskList = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [StoreComplianceToolResponse, setStoreComplianceToolResponse] = useState(
    props.StoreComplianceToolResponse
  );
  const [TaskList, setTaskList] = useState([]);

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  const getTaskList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreComplianceTool_TaskList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setTaskList(spResponse.Table);
      }
    }
  };

  useEffect(() => {
    getTaskList();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store</strong>
          </p>
          <p>{props.From.StoreName}</p>
        </div>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Task</th>
              <th>Due</th>
            </tr>
          </thead>
          <tbody>
            {TaskList.sort((a, b) => {
              if (a.IsOverdue - b.IsOverdue > 0) {
                return 1;
              }

              return 0;
            }).map((task, index) => {
              let backgroundColor = "white";

              if (task.IsOverdue === 1) {
                backgroundColor = "salmon";
              }

              return (
                <tr
                  key={index}
                  className={`btnHover ${task.IsOverdue === 1 && "background-salmon"}`}
                  onClick={() => {
                    props.setSelectedSubmissionID(task.SubmissionID);
                    props.setPage(`task${is.mobile() ? "-mobile" : ""}`);
                  }}
                >
                  <td>{task.TaskTitle}</td>
                  <td>{ConvertDateFormat(task.TaskDueDate, "en-CA")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p className="error-message">{StoreComplianceToolResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={blue}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
