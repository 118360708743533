import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const GiftCardPinCode = (props) => {
  const [PageResponse, setPageResponse] = useState("");

  const [PinCode, setPinCode] = useState("");

  const refGiftCardNo = useRef();

  const handleClick_GetPin = async () => {
    setPageResponse("");
    const resObj = await runFetch(`${spURL}Handheld_GiftCardPinCode_PinCode_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          GiftCardNo: refGiftCardNo.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPageResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setPinCode(spResponse.Table[0].PinCode || "");
      }
    }

    refGiftCardNo.current.select();
  };

  useEffect(() => {
    let ignore = false;

    refGiftCardNo.current.select();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <h1>
            <strong>Gift Card Pin Code</strong>
          </h1>
          <div className="storetransfer-input-row">
            <p>
              <strong>Gift Card No:</strong>
            </p>
            <input
              type="text"
              ref={refGiftCardNo}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_GetPin)}
            />
          </div>
          <div className="storetransfer-input-row">
            <p>
              <strong>Pin:</strong>
            </p>
            <p>{PinCode}</p>
          </div>
        </div>
        <br />
        <p className="error-message">{PageResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <FooterButton
          button="Pin"
          colour={purple}
          onClick={handleClick_GetPin}
        />
      </div>
    </div>
  );
};
