import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const GiftCardLookup = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [WebOrdersResponse, setWebOrdersResponse] = useState(props.MenuResponse);

  const [GiftCardDetails, setGiftCardDetails] = useState({
    OrderID: "",
    OrderDate: "",
    ShippingFullName: "",
    GiftCardAmount: "",
    ExpiryDate: "",
    PinCode: "",
  });

  const refGiftCardBarcode = useRef();

  const handleClick_Search = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_GiftCard_Lookup`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          GiftCardBarcode: refGiftCardBarcode.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setWebOrdersResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setGiftCardDetails({
          OrderID: spResponse.Table[0].OrderID,
          OrderDate: ConvertDateFormat(spResponse.Table[0].OrderDate),
          ShippingFullName: spResponse.Table[0].ShippingFullName,
          GiftCardAmount: spResponse.Table[0].GiftCardAmount,
          ExpiryDate: ConvertDateFormat(spResponse.Table[0].ExpiryDate),
          PinCode: spResponse.Table[0].PinCode,
        });
      } else {
        setWebOrdersResponse("ERROR!: Gift card not found.");
      }
    }
  };

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("menu");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Gift Card</strong>
          </p>
          <input
            type="text"
            id="weborders-giftcardlookup-giftcardbarcode"
            ref={refGiftCardBarcode}
            autoFocus
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
        </div>
        <button
          className="signin-login-button"
          id="weborders-enter"
          onClick={handleClick_Search}
        >
          Scan
        </button>
        <br />
        <p className="error-message">{WebOrdersResponse}</p>
        <div>
          {GiftCardDetails.OrderID === "" ? (
            ""
          ) : (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>OrderID</strong>
                </p>
                <p>{GiftCardDetails.OrderID}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>OrderDate</strong>
                </p>
                <p>{GiftCardDetails.OrderDate}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Name</strong>
                </p>
                <p>{GiftCardDetails.ShippingFullName}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Amount</strong>
                </p>
                <p>{GiftCardDetails.GiftCardAmount}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Expiry Date</strong>
                </p>
                <p>{GiftCardDetails.ExpiryDate}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Pin</strong>
                </p>
                <p>{GiftCardDetails.PinCode}</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
