import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { blue, getAccessData, red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Menu = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("list")}
            button="L"
            colour={blue}
            title="List"
          />
          {Access.CheckListManage && (
            <MenuButton
              onClick={() => props.setPage("manage")}
              button="M"
              colour={blue}
              title="Manage"
            />
          )}
          {Access.CheckListAdmin && (
            <MenuButton
              onClick={() => props.setPage("template")}
              button="T"
              colour={blue}
              title="Template"
            />
          )}
        </div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={blue}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
