import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, blue } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupCopyTemplate = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refTemplateName = useRef();
  const refNote = useRef();

  const cleanPopup = () => {
    setPopupResponse("");
    refTemplateName.current.value = "";
    refNote.current.value = "";
  };

  const handleClose = () => {
    cleanPopup();
    props.setShow(false);
  };

  const handleShow = () => {
    cleanPopup();
    refTemplateName.current.focus();
  };

  const handleClick_Create = async () => {
    setPopupResponse("");
    const resObj = await runFetch(`${spURL}Handheld_CheckList_Template_Copy`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          OriginalTemplateID: props.SelectedTemplate.TemplateID,
          TemplateName: refTemplateName.current.value,
          Note: refNote.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      setPopupResponse("");
      await props.getTemplateList();
      props.setSelectedTemplate({
        TemplateID: spResponse.Table[0].TemplateID,
        TemplateName: spResponse.Table[0].TemplateName,
        Note: spResponse.Table[0].Note,
        IsActive: spResponse.Table[0].IsActive,
      });
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h1>
              <strong>COPY Template</strong>
            </h1>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name</strong>
              </p>
              <input
                type="text"
                ref={refTemplateName}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Note</strong>
              </p>
              <textarea
                type="text"
                ref={refNote}
                rows={5}
                defaultValue="Insert note here..."
              ></textarea>
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Create}
            >
              Copy
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
