import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { green, orange, spURL, spURL_NoInputJSON } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";
import { isMobileTablet } from "../../functions/IsMobileTablet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const JobNoLines = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);
  const [SSCCTable, setSSCCTable] = useState([]);

  const [FilterSSCC, setFilterSSCC] = useState("");
  const [FilterCategory, setFilterCategory] = useState("");

  const [SortObj, setSortObj] = useState({
    SortColumn: "SSCC",
    SortOrder: "",
    SortIcon: solid("sort"),
  });

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("whreceive");
  };

  const handleClick_HeaderRow = (colname, sortorder) => {
    let sortIcon = solid("sort");

    if (sortorder === "ASC") {
      sortIcon = solid("sort-up");
    }

    if (sortorder === "DESC") {
      sortIcon = solid("sort-down");
    }

    setSortObj({
      ...SortObj,
      SortColumn: colname,
      SortOrder: sortorder,
      SortIcon: sortIcon,
    });
  };

  useEffect(() => {
    async function getSSCCList() {
      const resObj = await runFetch(`${spURL}Handheld_WHReceived_JobNo_Lines_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            JobNo: props.JobNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setSSCCTable(spResponse.Table);
        } else {
          setMenuResponse("ERROR!: No stores found.");
        }
      }
    }
    getSSCCList();
  }, []);

  return (
    <div className="page-container">
      <div className={`${isMobileTablet() ? "handheld" : "blockedorders"}-body-container`}>
        <p>
          <strong>JobNo: {props.JobNo}</strong>
        </p>
        <div className="stocktake-create-input-row">
          <p>
            <strong>SSCC</strong>
          </p>
          <select onChange={(e) => setFilterSSCC(e.target.value)}>
            <option value="">ALL</option>
            {[...new Set(SSCCTable.map((sscc) => sscc.SSCC))].sort().map((sscc) => (
              <option value={sscc}>{sscc}</option>
            ))}
          </select>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Category</strong>
          </p>
          <select onChange={(e) => setFilterCategory(e.target.value)}>
            <option value="">ALL</option>
            {[...new Set(SSCCTable.map((sscc) => sscc.Category))].sort().map((cat) => (
              <option value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <p className="error-message">{MenuResponse}</p>
        <table className="itemdetails-table">
          <thead>
            <tr className="btnHover no-highlight">
              {isMobileTablet() ? (
                <>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "SSCC",
                        SortObj.SortColumn === "SSCC" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    SSCC
                    <br />
                    {SortObj.SortColumn === "SSCC" && <FontAwesomeIcon icon={SortObj.SortIcon} />}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "ItemNo",
                        SortObj.SortColumn === "ItemNo" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    Item
                    <br />
                    {SortObj.SortColumn === "ItemNo" && <FontAwesomeIcon icon={SortObj.SortIcon} />}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "TOQty",
                        SortObj.SortColumn === "TOQty" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    Qty
                    <br />
                    {SortObj.SortColumn === "TOQty" && <FontAwesomeIcon icon={SortObj.SortIcon} />}
                  </th>
                </>
              ) : (
                <>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "SSCC",
                        SortObj.SortColumn === "SSCC" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    SSCC
                    <br />
                    {SortObj.SortColumn === "SSCC" && <FontAwesomeIcon icon={SortObj.SortIcon} />}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "Department",
                        SortObj.SortColumn === "Department" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    Department
                    <br />
                    {SortObj.SortColumn === "Department" && (
                      <FontAwesomeIcon icon={SortObj.SortIcon} />
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "Category",
                        SortObj.SortColumn === "Category" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    Category
                    <br />
                    {SortObj.SortColumn === "Category" && (
                      <FontAwesomeIcon icon={SortObj.SortIcon} />
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "ItemNo",
                        SortObj.SortColumn === "ItemNo" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    ItemNo
                    <br />
                    {SortObj.SortColumn === "ItemNo" && <FontAwesomeIcon icon={SortObj.SortIcon} />}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "ItemDescription",
                        SortObj.SortColumn === "ItemDescription" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    Description
                    <br />
                    {SortObj.SortColumn === "ItemDescription" && (
                      <FontAwesomeIcon icon={SortObj.SortIcon} />
                    )}
                  </th>
                  <th
                    onClick={() =>
                      handleClick_HeaderRow(
                        "TOQty",
                        SortObj.SortColumn === "TOQty" && SortObj.SortOrder === "ASC"
                          ? "DESC"
                          : "ASC"
                      )
                    }
                  >
                    Qty
                    <br />
                    {SortObj.SortColumn === "TOQty" && <FontAwesomeIcon icon={SortObj.SortIcon} />}
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {SSCCTable.filter(
              (sscc) =>
                (sscc.SSCC === FilterSSCC || FilterSSCC === "") &&
                (sscc.Category === FilterCategory || FilterCategory === "")
            )
              .sort((a, b) => {
                if (SortObj.SortColumn !== "") {
                  switch (SortObj.SortColumn) {
                    case "TOQty":
                      return SortObj.SortOrder === "ASC"
                        ? parseFloat(a[SortObj.SortColumn]) - parseFloat(b[SortObj.SortColumn])
                        : parseFloat(b[SortObj.SortColumn]) - parseFloat(a[SortObj.SortColumn]);
                    case "SSCC":
                    case "Department":
                    case "Category":
                    case "ItemNo":
                    case "ItemDescription":
                    default:
                      return SortObj.SortOrder === "ASC"
                        ? a[SortObj.SortColumn].localeCompare(b[SortObj.SortColumn])
                        : b[SortObj.SortColumn].localeCompare(a[SortObj.SortColumn]);
                  }
                }

                return 1;
              })
              .map((item, index) => (
                <tr
                  key={index}
                  style={{ fontSize: 13 }}
                >
                  {isMobileTablet() ? (
                    <>
                      <td>{item.SSCC}</td>
                      <td>
                        {item.ItemNo}
                        <br />
                        <strong>{item.ItemDescription}</strong>
                        <br />
                        {item.Department}
                      </td>
                      <td>{item.TOQty}</td>
                    </>
                  ) : (
                    <>
                      <td>{item.SSCC}</td>
                      <td>{item.Department}</td>
                      <td>{item.Category}</td>
                      <td>{item.ItemNo}</td>
                      <td>{item.ItemDescription}</td>
                      <td>{item.TOQty}</td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          onClick={handleClick_Back}
          button="Back"
          colour={orange}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
