import React, { useState, useEffect } from "react";
import { Menu } from "./Menu";
import { Template } from "./Template";
import { Manage } from "./Manage";

export const CheckListBody = () => {
  const [page, setPage] = useState("menu");

  const menuPage = <Menu setPage={setPage} />;

  const templatePage = (
    <Template
      page={page}
      setPage={setPage}
    />
  );

  const managePage = (
    <Manage
      page={page}
      setPage={setPage}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "template":
        return templatePage;
      case "manage":
        return managePage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
