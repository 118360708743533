import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import {
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
  spURL_LocalLabel,
} from "../../App";
import { runFetch, runLabelFetch } from "../../functions/RunFetch";
import store from "../../store";

export const PopupPrint = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [PrintDetailList, setPrintDetailList] = useState([]);
  const [ddlLabelType, setDDLLabelType] = useState([]);

  const handleClose = () => {
    setConfirmResponse("");
    props.setShow(false);

    if (document.getElementById("itembarcode-barcode")) {
      document.getElementById("itembarcode-barcode").focus();
    }
  };

  const handleShow = async () => {
    setConfirmResponse("");

    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Print_History`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          BatchID: props.BatchID,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setConfirmResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setPrintDetailList(
          spResponse.Table.map((item, index) => ({
            LabelType: item.LabelType,
            Qty: item.Qty,
          }))
        );
      } else {
        setPrintDetailList([]);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setDDLLabelType(
          spResponse.Table1.map((item, index) => (
            <option
              key={index}
              value={item.LabelType}
            >
              {item.LabelType}
            </option>
          ))
        );
      } else {
        setDDLLabelType("");
      }
    }
  };

  const handleClick_Print = async () => {
    setConfirmResponse("");
    const _labeltype = document.getElementById("labels-print-labeltype").value;

    let func = "";
    let inputObj = {};
    switch (_labeltype) {
      case "SHELF":
        func = "standardshelf/batchprint";
        inputObj = {
          From: props.From,
          StoreNo: storeno,
          BatchName: props.BatchID,
          ShelfPrinter: "",
          ItemNo: "",
          Quantity: 0,
        };
        break;
      case "PROMO":
        func = "dealshelf/batchprint";
        inputObj = {
          From: props.From,
          StoreNo: storeno,
          BatchName: props.BatchID,
          ShelfPrinter: "",
          ItemNo: "",
          Quantity: 0,
        };
        break;
      case "A4":
      default:
        func = "pdf/create";
        inputObj = {
          From: props.From,
          StoreNo: storeno,
          BatchName: props.BatchID,
          LabelType: _labeltype,
        };
        break;
    }

    await runLabelFetch(
      `${spURL_LocalLabel}${func}`,
      {
        method: "POST",
        body: new URLSearchParams(inputObj),
      },
      (res) => {
        props.setMenuResponse(res);
        handleClose();
      },
      (err) => {
        setConfirmResponse(err);
      }
    );
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>Batch List - {props.BatchID}</strong>
            <br />
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {PrintDetailList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.LabelType}</td>
                    <td>{item.Qty}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <div className="input-row">
              <p>
                <strong>Print:</strong>
              </p>
              <select
                name="labeltype"
                id="labels-print-labeltype"
              >
                <option value=""></option>
                {ddlLabelType}
              </select>
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Print}
            >
              Print
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
