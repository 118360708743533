import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { blue, getAccessData, red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Manage = (props) => {
  const [CheckListResponse, setCheckListResponse] = useState("");
  const [userid, setUserID] = store.useState("userid");
  const [storeno, setStoreNo] = store.useState("storeno");

  const [CheckListList, setCheckListList] = useState([]);

  const emptyCheckList = {
    CheckListID: "0",
    TemplateID: "0",
    TemplateName: "",
    Notes: "",
    AssignedtoStaffID: "",
    AssignedtoStaffName: "",
  };
  const [SelectedCheckList, setSelectedCheckList] = useState(emptyCheckList);

  const getStoreCheckList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CheckList_DDLManage_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setCheckListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setCheckListList(spResponse.Table);
      }
    }
  };

  useEffect(() => {
    getStoreCheckList();
  }, []);

  return (
    <div className="unallocatedorders-page-container">
      <div className="webbuffer-top-row">
        <h4>
          <strong>Manage Check List</strong>
        </h4>
      </div>
      <div>
        <div className="blockedorders-content-container">
          <p className="error-message">{CheckListResponse}</p>
          {SelectedCheckList.CheckListID === "0" ? (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Template</th>
                </tr>
              </thead>
              <tbody>
                {CheckListList.map((checklist, index) => (
                  <tr
                    className="btnHover"
                    key={index}
                    onClick={() => {
                      setCheckListResponse("");
                      setSelectedCheckList(checklist);
                    }}
                  >
                    <td>{checklist.TemplateName}</td>
                    <td>{checklist.AssignedtoStaffName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Hello world</p>
          )}
        </div>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
