import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import {
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
  yellow,
} from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { useRef } from "react";
import { PopupPriceChangesCreateBatch } from "./PopupPriceChangesCreateBatch";
import store from "../../store";

export const PriceChangesSettings = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [LabelResponse, setLabelResponse] = useState("");

  const [IsBatchSelected, setIsBatchSelected] = useState(false);
  const [SelectedBatch, setSelectedBatch] = useState("");
  const [SelectedPrinter, setSelectedPrinter] = useState("");

  const [IsPrintQuickShelfChecked, setIsPrintQuickShelfChecked] = useState(true);
  const [IsPriceChangeModeEDM, setIsPriceChangeModeEDM] = useState(false);

  const [DDLBatchList, setDDLBatchList] = useState([]);
  const [DDLEDM, setDDLEDM] = useState([]);

  const refDDLBatchList = useRef();
  const refPrintQuickShelf = useRef();
  const refDDLPriceChangeMode = useRef();
  const refDDLEDM = useRef();

  const [showCreateBatch, setShowCreateBatch] = useState(false);

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  const handleClick_SelectBatch = () => {
    setLabelResponse("");

    if (IsBatchSelected) {
      setSelectedBatch("");
      setSelectedPrinter("");
      getDDLBatch();
    } else {
      setSelectedBatch(refDDLBatchList.current.value);
      setSelectedPrinter(refDDLBatchList.current.selectedOptions[0].attributes["printer"].value);
    }

    setIsBatchSelected(!IsBatchSelected);
  };

  const getDDLBatch = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_BatchList`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setLabelResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      setDDLBatchList(
        spResponse.Table.map((item) => ({
          BatchID: item.BatchID,
          Printer: item.ShelfPrinter,
        }))
      );
    }
  };

  const handleClick_SelectMode = () => {
    setLabelResponse("");

    props.setPriceChangesBatchDetails({
      Batch: SelectedBatch,
      Printer: SelectedPrinter,
      IsPrintQuickShelfChecked: IsPrintQuickShelfChecked,
      Mode: refDDLPriceChangeMode.current.value,
      EDM: IsPriceChangeModeEDM ? refDDLEDM.current.value : "",
    });

    props.setPage("pricechanges-categories");
  };

  const getEDMList = async () => {
    setLabelResponse("");

    const resObj = await runFetch(`${spURL}Handheld_Label_PriceChange_EDMList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setLabelResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLEDM(spResponse.Table);
      }
    }
  };

  const handleClick_Create = () => {
    setLabelResponse("");
    setShowCreateBatch(true);
  };

  useEffect(() => {
    getDDLBatch();
    getEDMList();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {IsBatchSelected ? (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Batch:</strong>
              </p>
              <p>{SelectedBatch}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Printer:</strong>
              </p>
              <p>{SelectedPrinter}</p>
            </div>
          </>
        ) : (
          <div className="stocktake-create-input-row">
            <p>
              <strong>Batch:</strong>
            </p>
            <select ref={refDDLBatchList}>
              <option value=""></option>
              {DDLBatchList.map((item, index) => (
                <option
                  key={index}
                  printer={item.Printer}
                  value={item.BatchID}
                >
                  {item.BatchID}
                </option>
              ))}
            </select>
          </div>
        )}
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_SelectBatch}
        >
          {IsBatchSelected ? "Cancel" : "Select"}
        </button>
        <br />
        <br />
        {IsBatchSelected ? (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Mode:</strong>
              </p>
              <select
                ref={refDDLPriceChangeMode}
                onChange={(e) => {
                  setIsPriceChangeModeEDM(e.target.value === "EDM");
                }}
              >
                <option value="PRICECHANGE">PRICECHANGE</option>
                <option value="STOREQUIT">STOREQUIT</option>
                <option value="EDM">EDM</option>
              </select>
            </div>
            <div className={`stocktake-create-input-row ${!IsPriceChangeModeEDM ? "hidden" : ""}`}>
              <p>
                <strong>EDM:</strong>
              </p>
              <select ref={refDDLEDM}>
                {DDLEDM.map((edm, index) => (
                  <option
                    value={edm.Promotion}
                    key={index}
                  >
                    {edm.Promotion}
                  </option>
                ))}
              </select>
            </div>

            <div className="stocktake-create-input-row">
              <p>
                <input
                  defaultChecked
                  type="checkbox"
                  ref={refPrintQuickShelf}
                  onChange={(e) => {
                    setLabelResponse("");
                    setIsPrintQuickShelfChecked(e.target.checked);
                  }}
                />
                {`  Print quick shelf`}
              </p>
            </div>
            <button
              className="signin-login-button btnHover"
              onClick={handleClick_SelectMode}
            >
              Select
            </button>
          </>
        ) : null}

        <p className="error-message">{LabelResponse}</p>

        <PopupPriceChangesCreateBatch
          show={showCreateBatch}
          setShow={setShowCreateBatch}
          setSelectedBatch={setSelectedBatch}
          setSelectedPrinter={setSelectedPrinter}
          setIsBatchSelected={setIsBatchSelected}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        {!IsBatchSelected && (
          <FooterButton
            button="Create"
            colour={yellow}
            onClick={handleClick_Create}
          />
        )}
      </div>
    </div>
  );
};
