import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const PopupTaskImage = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const [ImageURL, setImageURL] = useState("");
  const [SubmissionComment, setSubmissionComment] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreComplianceToolAdmin_Task_Image_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TaskID: props.SelectedTaskID,
          StoreNo: props.SelectedStore.StoreNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setImageURL(spResponse.Table[0].ImageURL);
        setSubmissionComment(spResponse.Table[0].SubmissionComment);
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store: </strong>
              </p>
              <p>{props.SelectedStore.StoreName}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Comment: </strong>
              </p>
              <textarea
                rows={10}
                disabled
                value={SubmissionComment}
              ></textarea>
            </div>
            <br />
            <img src={ImageURL} />
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
